import React, { useState, useRef, useEffect } from "react"

import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap"

import { CART_ITEM_URL, LOANCART_ITEM_URL } from "../../constants"

//import axios from "axios"
import api from "../../api"

const RemoveCartItemModal = ({ item, resetState, isLoan }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const deleteItem = async () => {
    try {
      await api.delete(`${CART_ITEM_URL}${item.pk}`)
      toggle()
      resetState()
      console.log("Item deleted successfully")
    } catch (error) {
      console.error("Error deleting item:", error)
    }
  }

  const deleteLoanItem = async () => {
    try {
      await api.delete(`${LOANCART_ITEM_URL}${item.pk}`)
      toggle()
      resetState()
    } catch (error) {
      console.error("Error deleting item:", error)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const yesButton = document.querySelector('.yes-button');
      if (yesButton) {
        yesButton.focus(); // Manually focus on the "Yes" button on Enter press
      }
    }
  };

  useEffect(() => {
    if (modal) {
      // Add event listener for the "Enter" key
      document.addEventListener("keydown", handleKeyDown);

      // Clean up event listener on unmount
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [modal]);

  return (
    <>
      <Button
        color="danger"
        onClick={toggle}
        style={{
          padding: "5px", // Reduce padding to make items smaller
          border: "1px solid #ccc",
          margin: "0 5px",
        }}
      >
        Remove
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Do you really want to delete this item?
        </ModalHeader>

        <ModalBody>{item.name}</ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={isLoan ? deleteLoanItem : deleteItem}
            className="yes-button"
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RemoveCartItemModal