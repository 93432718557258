// Updated to Modern React 08/01/2024

import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col,
} from "reactstrap"
import axios from "axios" // Axios is used for HTTP interactions. In this file, POST and PUT.
import { EQUIPMENT_URL } from "../../constants"

import api from "../../api"

const EquipmentForm = ({ equipment, resetState, toggle }) => {
  const [formData, setFormData] = useState({
    pk: 0,
    icn: 0,
    name: "",
    manufacturer: "",
    model: "",
    serialNumber: 0,
    price: 0,
    location: "",
    acquiredDate: null,
    lastSeenDate: null,
    lastSeenLocation: "",
    comments: "",
    errorMessage: "",
  })

  useEffect(() => {
    if (equipment) {
      const {
        pk,
        icn,
        name,
        manufacturer,
        model,
        serialNumber,
        price,
        location,
        acquiredDate,
        lastSeenDate,
        lastSeenLocation,
        comments,
      } = equipment
      setFormData({
        pk,
        icn,
        name,
        manufacturer,
        model,
        serialNumber,
        price,
        location,
        acquiredDate,
        lastSeenDate,
        lastSeenLocation,
        comments,
        errorMessage: "",
      })
    }
  }, [equipment])

  const handleChange = (e) => {
    const { name, type, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "number" ? Number(value) : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const {
      pk,
      name,
      manufacturer,
      model,
      serialNumber,
      price,
      location,
      acquiredDate,
      lastSeenDate,
      lastSeenLocation,
      comments,
    } = formData

    if (
      !name ||
      !manufacturer ||
      !model ||
      !serialNumber ||
      !price ||
      !location
    ) {
      setFormData((prevState) => ({
        ...prevState,
        errorMessage: "Please fill out all required fields.",
      }))
      return
    }

    try {
      if (pk) {
        await api.put(`${EQUIPMENT_URL}${pk}`, formData)
      } else {
        await api.post(`${EQUIPMENT_URL}create/`, formData)
      }
      resetState()
      toggle()
    } catch (error) {
      console.error("Error submitting form:", error)
      setFormData((prevState) => ({
        ...prevState,
        errorMessage: processErrors(error.response.data),
      }))
    }
    // catch (error) {
    //   console.error("Error submitting form:", error)
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     errorMessage: `Error ${
    //       pk ? "editing" : "creating"
    //     } equipment.\n${Object.entries(error.response.data)
    //       .map(([field, messages]) =>
    //         messages.map((field, message) => `${field}: ${message}`)
    //       )
    //       .flat()
    //       .join("\n")}`,
    //   }))
    // }
  }

  const messageBank = {
    acquiredDate: "Make sure you enter in a valid aquired date",
    lastSeenDate: "Make sure you enter in a valid last seen date",
    lastSeenLocation: "Make sure to provide the last seen location.",
    comments: "Make sure that you have included a comment.",
  }

  const processErrors = (errorData) => {
    return Object.keys(errorData).map((field, index) => (
      <p key={index}>{messageBank[field] || `Unknown error in ${field}`}</p>
    ))
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formData.errorMessage && (
        <Alert color="danger">{formData.errorMessage}</Alert>
      )}
      <Row>
        <Col>
          <FormGroup>
            <Label for="name">
              Name:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="name"
              onChange={handleChange}
              value={formData.name || ""}
              placeholder="Ex: Multimeter"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="manufacturer">
              Manufacturer:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="manufacturer"
              onChange={handleChange}
              value={formData.manufacturer || ""}
              placeholder="Ex: God"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="model">
              Model:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="model"
              onChange={handleChange}
              value={formData.model || ""}
              placeholder="Ex: 1TENNESSEE"
            />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <Label for="serialNumber">
              Serial Number:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="serialNumber"
              onChange={handleChange}
              value={formData.serialNumber || ""}
              placeholder="Ex: A313D31"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="price">
              Price:<span className="required">*</span>
            </Label>
            <Input
              type="number"
              name="price"
              onChange={handleChange}
              value={formData.price || ""}
              placeholder="Ex: 10.38"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="location">
              Location:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="location"
              onChange={handleChange}
              value={formData.location || ""}
              placeholder="Ex: Cabinet 2:3"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup>
          <Label for="icn">ICN:</Label>
          <Input
            type="text"
            name="icn"
            onChange={handleChange}
            value={formData.icn || ""}
            placeholder="A31313B"
          />
        </FormGroup>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="acquiredDate">Acquired Date:</Label>
            <Input
              type="date"
              name="acquiredDate"
              onChange={handleChange}
              value={formData.acquiredDate || ""}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="lastSeenDate">Last Seen Date:</Label>
            <Input
              type="date"
              name="lastSeenDate"
              onChange={handleChange}
              value={formData.lastSeenDate || ""}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="lastSeenLocation">Last Seen Location:</Label>
            <Input
              type="text"
              name="lastSeenLocation"
              onChange={handleChange}
              value={formData.lastSeenLocation || ""}
              placeholder="Ex: Cabinet 9:4"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="comments">Comments:</Label>
            <Input
              type="text"
              name="comments"
              onChange={handleChange}
              value={formData.comments || ""}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button type="submit">Save</Button>
    </Form>
  )
}

export default EquipmentForm
