import React, { useState } from "react"

import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

import api from "../../api"

const CompleteModal = ({ project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)
  const [input, setInput] = useState("UN")

  const STATUS_CHOICES = [
    ["UN", "Unfulfilled"],
    ["IP", "In Progress"],
    ["CP", "Completed"],
    ["AR", "Archived"],
    ["CN", "Canceled"],
  ]

  const onChange = (e) => {
    e.preventDefault()

    setInput(e.target.value)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const changeStatus = async () => {
    try {
      await api.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: input }, // Body of the request
        {
          headers: {
            "Content-Type": "application/json", // Ensure you're sending JSON data
          },
        }
      )
      toggle() // Close the modal
      resetState() // Refresh the list of items
    } catch (error) {
      console.error("Error canelling project:", error)
    }
  }

  return (
    <div>
      <Button onClick={toggle} className="m-4">
        Status
      </Button>
      <Modal isOpen={modal}>
        <ModalHeader>Change status for {project.customerName}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Input
              type="select"
              name="status"
              onChange={onChange}
              value={input}
            >
              {STATUS_CHOICES.map((choice) => (
                <option key={choice[0]} value={choice[0]}>
                  {choice[1]}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
          <Button color="primary" onClick={changeStatus}>
            Change Status
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CompleteModal
