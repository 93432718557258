// 3/6/24 - Tyler Perkins
// Logic for adding an item to the cart.
// Updated to modern React 07/26/2024

import React, { useState, Fragment, useRef, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import QuantityForm from "./QuantityForm"

const AddToCartModal = ({ resetState, item }) => {
  const [modal, setModal] = useState(false)

  // Change whether the frame is viewable or not
  const toggle = () => {
    setModal(!modal)
  }

  return (
    <Fragment>
      <Button color="primary" onClick={toggle} className="m-4">
        Add to Cart
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Enter item quantity</ModalHeader>
        <ModalBody>
          <QuantityForm resetState={resetState} toggle={toggle} item={item} defaultValue={1} />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default AddToCartModal
