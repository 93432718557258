import React from "react"

import { Button, Col, Row } from "reactstrap"

import CheckoutModal from "./CheckoutModal"
import MiscModal from "./MiscModal"
import MiscEquipmentModal from "./MiscEquipmentModal"

const CartHeader = ({
  setLoadedCart,
  resetState,
  totalPrice,
  totalLoans,
  cartID,
  loanCartID,
  group,
  setGroup,
}) => {
  return (
    <>
      <div className="title-text">
        <h2>Cart</h2>
      </div>
      <Row className="mt-3 mb-3">
        <Col className="d-flex justify-content-start gap-2">
          <MiscModal resetState={resetState} />
          <MiscEquipmentModal resetState={resetState} />
        </Col>
        <Col className="d-flex justify-content-end">
          <CheckoutModal
            resetState={resetState}
            totalPrice={totalPrice}
            totalLoans={totalLoans}
            cartID={cartID}
            loanCartID={loanCartID}
            group={group}
            transactionType={"refund"}
          />
          <CheckoutModal
            resetState={resetState}
            totalPrice={totalPrice}
            totalLoans={totalLoans}
            cartID={cartID}
            loanCartID={loanCartID}
            group={group}
            transactionType={"charge"}
            setGroup={setGroup}
          />
        </Col>
      </Row>
    </>
  )
}

export default CartHeader
