import React, { useState, useRef } from "react"
import {
  Button,
  Modal,
  Form,
  Label,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { DayPicker } from "react-day-picker"

import { LOANCART_ITEM_URL } from "../../constants"

import api from "../../api"

const MiscModal = ({ resetState }) => {
  const [modal, setModal] = useState(false)
  const [name, setName] = useState("")
  const inputRef = useRef(null)
  const [date, setDate] = useState()

  const currentYear = new Date().getFullYear()
  const startMonth = new Date(currentYear, 0)
  const endMonth = new Date(currentYear + 10, 11)

  const formatDate = (date) => {
    return date ? date.toISOString().split("T")[0] : ""
  }

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const toggle = () => {
    setModal(!modal)
    setName("")
    setDate()
    resetState()
  }

  const createMiscEquipment = async (e) => {
    const payLoad = {
      miscName: name,
      returnDate: formatDate(date),
    }

    try {
      const response = await api.post(LOANCART_ITEM_URL, payLoad)
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      createMiscEquipment()
      toggle()
      resetState()
    }
  }

  return (
    <>
      <Button
        onClick={toggle}
        style={{ minWidth: "80px" }}
        className="m-1 mb-4 mt-4 float-end"
      >
        Misc Equipment
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        onOpened={() => inputRef.current?.focus()}
      >
        <ModalHeader toggle={toggle}>Miscellaneous Equipment</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Equipment Name</Label>
              <Input
                type="text"
                name="name"
                onChange={onNameChange}
                value={name}
                innerRef={inputRef}
                onKeyDown={handleEnter}
              />
            </FormGroup>

            <DayPicker
              className="day-picker"
              mode="single"
              selected={date}
              onSelect={setDate}
              max={1}
              hideNavigation
              captionLayout="dropdown"
              startMonth={startMonth}
              endMonth={endMonth}
            />

            <Button
              onClick={() => {
                createMiscEquipment()
                toggle()
                resetState()
              }}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default MiscModal
