import React, { useState } from "react"

import { Button, Modal } from "reactstrap"

import { MAIN_CART_ID, CART_ITEM_URL } from "../../constants"

import api from "../../api"

const CompleteModal = ({ project, resetState, projectType }) => {
  const [isClicked, setIsClicked] = useState(false)

  const createMiscItem = async (e) => {
    const payLoad = {
      miscName: `${project.customerName}'s item`,
      miscPrice: project.price,
      quantity: 1,
      project_pk: project.pk,
      project_type: projectType,
    }

    try {
      const response = await api.post(
        process.env.REACT_APP_CART_ITEM_CREATE_URL,
        payLoad
      )
      console.log(response)
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  const handleClick = () => {
    setIsClicked(true)
    createMiscItem()
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        className="m-4"
        {...(isClicked && { outline: true })}
        color="success" // Change color based on state
        // style={{
        //   color: isClicked ? "green" : "white", // Invert text color
        //   backgroundColor: isClicked ? "white" : "green", // Invert background color
        // }}
      >
        Add to Cart
      </Button>
    </div>
  )
}

export default CompleteModal
